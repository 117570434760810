export type AssetType = {
  alias: string;
  src: string;
};
export enum AnimationsName {
  FANFARES_SKELETON = "fanfares",
  EXPEDITION_CHESTS_SKELETON='chest_skeleton',
  CHEST_SKELETON = "chest",
  STARS_SKELETON = "stars",
  ADS_CHEST_SKELETON = "ads_chest",

  FANFARES_ATLAS = "fanfares_atlas",
  CHEST_ATLAS = "chest_atlas",
  STARS_ATLAS = "stars_atlas",
  ADS_CHEST_ATLAS = "ads_atlas",
  EXPEDITION_CHESTS_ATALS='chest_atlas',
  
  FANFARES = "action",
  STARS = "shining3",
  ADS_CHEST = "floating_chest",
  CHEST_2X_3X = "shining1_v2",
  CHEST_4X = "shining2",
  CHEST_5X = "shining3",
  EXPEDITION_CHEST_HIGH='chest_high',
  EXPEDITION_CHEST_LOW='chest_low',
  EXPEDITION_CHEST_MEDIUM='chest_medium',
}

const VERSION = `v=1.0.0`;

export const atlasArray: AssetType[] = [
  {
    alias: AnimationsName.FANFARES_ATLAS,
    src: `/assets/animations/fanfares/fanfare.atlas?${VERSION}`,
  },
  {
    alias: AnimationsName.CHEST_ATLAS,
    src: `/assets/animations/chest/chest.atlas?${VERSION}`,
  },
  {
    alias: AnimationsName.STARS_ATLAS,
    src: `/assets/animations/stars/stars.atlas?${VERSION}`,
  },
  {
    alias: AnimationsName.ADS_CHEST_ATLAS,
    src: `/assets/animations/ads_chest/floating_chest.atlas?${VERSION}`,
  },
  {
    alias: AnimationsName.EXPEDITION_CHESTS_ATALS,
    src: `/assets/animations/expedition/chests_expedition_vfx.atlas?${VERSION}`,
  }
 
];

export const skeletonArray: AssetType[] = [
  {
    alias: AnimationsName.FANFARES_SKELETON,
    src: `/assets/animations/fanfares/fanfare.json?${VERSION}`,
  },
  {
    alias: AnimationsName.CHEST_SKELETON,
    src: `/assets/animations/chest/chest.json?${VERSION}`,
  },
  {
    alias: AnimationsName.STARS_SKELETON,
    src: `/assets/animations/stars/stars.json?${VERSION}`,
  },
  {
    alias: AnimationsName.ADS_CHEST_SKELETON,
    src: `/assets/animations/ads_chest/floating_chest.json?${VERSION}`,
  },
  {
    alias: AnimationsName.EXPEDITION_CHESTS_SKELETON,
    src: `/assets/animations/expedition/chests_expedition_vfx.json?${VERSION}`,
  }
];
